/* Importing Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap');

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    background: rgba(126, 175, 253, 0.7);
}

.modal-content {
    background: #fff;
    padding: 50px;
    border-radius: 15px;
    border: 2px solid #ff6f61;
    max-width: 90vw;
    width: 640px;
    max-height: 70vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.modal-close {
    position: absolute;
    right: 40px;
    top: 40px;
    background: #ff6f61;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    min-width: 10%;
    min-height: 10%;
    font-size: 20px;
    font-weight: 700;
}

.modal-close-fat {
    position: absolute;
    right: 40px;
    top: 40px;
    background: #ff6f61;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    min-width: 5%;
    font-size: 25px;
}


.modal-close:hover {
    background: #ff5a4d;
}

.modal-content h2 {
    font-weight: 700;
    font-size: 2.7rem;
    color: #333;
    padding-bottom: 10px;
    margin-bottom: 15px;
    text-align: center;
    padding-right: 50px;
    position: relative;
    z-index: 100;
}

.modal-content p {
    /* font-weight: 700; */
    font-size: 1.2rem;
    color: #444;
    text-align: justify;
}

.modal-content p span {
    color: #002279;
}

.modal-image {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50%;
    height: 50%;
    object-fit: contain;
    border-radius: 10px;
}

.modal-image-fat {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 57%;
    height: 60%;
    padding: 20px;
    object-fit: contain;
    border-radius: 10px;
}

.span-fat {
    font-size: 1.2rem;
}

@media (max-width: 768px) {
    .modal-content {
        max-width: 350px;
        max-height: 60vh;
        padding: 20px;
    }

    .span-fat {
        font-size: 1.3rem;
    }

    .modal-image-fat {
        position: absolute;
        bottom: 0;
        right: 5px;
        object-fit: cover;
        width: 52%;
        height: 66%;
    }

    .modal-close {
        top: 22px;
        right: 22px;
        font-family: 'Amatic SC Regular', cursive;
        font-weight: 700;
        font-size: 20px;
        padding:0;
    }

    .modal-close-fat {
        top: 26px;
        right: 26px;
        font-family: 'Amatic SC Regular', cursive;
        font-weight: 700;
        font-size: 20px;
        padding:0;
    }

    .modal-content h2 {

        font-family: 'Amatic SC', cursive;
        font-weight: 700;
        font-size: 2rem;
    }

    .modal-content p {
        font-family: 'Amatic SC', cursive;
        font-weight: 700;
        font-size: 1.5rem;
    }

    .modal-image {
        position: absolute;
        bottom: 0;
        right: 5px;
        height: 78%;
        object-fit: cover;
        width: 59%;
    }
}
