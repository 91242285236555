@import url('https://fonts.googleapis.com/css2?family=Limelight&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Space+Grotesk:wght@300..700&display=swap');

html, body {
    font-family: "Space Grotesk", sans-serif;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
    margin: 0;
    background-color: #75a0f6;;;
    background-color: rgb(248, 239, 223);
    /* background-color: black; */
}

body, html, .container {
    overflow-x: hidden;
}


.calculator-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 50vw;

    font-weight: 400;
}

.calculator-container header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    width: 100%;
}

header h2 {
    font-size: 3rem;
    font-weight: 700;
    color: #333;
    color: #75a0f6;
    text-align: center;
}


.container {
    background: white;
    padding: 30px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    max-width: 90vw;
    width: 100%;
    min-width: 40vw;
    position: relative;
    overflow-x: hidden;
    height: auto;
    min-height: auto;
    margin: 20px auto;
    /* border-top-left-radius: 50%;
    border-top-right-radius: 50%; */
border-radius: 4px;


}

.form-content {
    width: 90%;
    max-height: none;
    font-size: 1rem;
    font-weight: 500;
    color:  #013baf;
    text-transform: uppercase;

}

.form-img {
    max-height: 250px;
    width: auto;
    display: block;
    margin-bottom: 10px;

}

.form-img img {
    max-height:250px;
    width: auto;
    margin: 0 auto;
    display: block;
}



.container h2 {
    font-weight: 700;
    font-size: 2.5rem;
    color: #333;
    margin-top: 0;
    text-transform: uppercase;
}

.input-group {
    margin-bottom: 15px;
    position: relative;}

.input-group label {
    display: block;
    margin-bottom: 5px;
    font-size: x-large;
}

.input-group input,
.input-group select {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    font-size: larger;
    font-weight: bold;
}

.input-group i {
    padding-left: 10px;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
}

.question-mark {
    display: inline-block;
    background-color: #f94433;
    color: white;
    border-radius: 50%;
    width: 30px;
    text-align: center;
    align-content: center;
    font-weight: bold;
    font-family: 'Amatic SC Regular', cursive;
    cursor: pointer;
    position: absolute;
    top: 47px;
    right: -39px;
}

.custom-tooltip {
    text-align: center;
    z-index: 1;
    top: -35px;
    right: 0;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
    background-color: #333;
    color: white;
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 15px;
    max-width: 200px;
    white-space: normal;
    position: relative;
    word-wrap: break-word;
}

.error {
    color: red;
    display: none;
    margin-top: 5px;
}

button {
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: 700;
    font-family: "Space Grotesk", sans-serif;

}

button[type="submit"] {
    padding: 8px 30px;
    font-size: 1.7rem;
    font-weight: 800;
    background: #75a0f6;
}


button:hover {
    background: linear-gradient(135deg, #ff5a4d 0%, #f63900 100%);}

button:disabled {
    background: #ccc;
    cursor: not-allowed;
}

.reset-btn {
    background: linear-gradient(135deg, #ffab91 0%, #ff6f61 100%);
}

.reset-btn:hover {
    background: linear-gradient(135deg, #ff6f61 0%, #ff5a4d 100%);
}

.button-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    gap: 10px;
}

.button-group button {
    width: auto;
    text-transform: uppercase;
    padding: 10px 30px;
    font-weight: 400;
}


@media (max-width: 768px) {
    .container {
        flex-direction: column;
        max-height: none;
        min-width: 90vw;
    }

    .form-img img {
        max-height: 250px;
        width: auto;
    }


    .form-content {
        margin-top: 15px;
    }
    .form-img {
        max-height: 200px;
        width: auto;
    }

    .button-group {
        flex-direction: column;
        align-items: center;
    }

    .button-group button {
        width: 100%;
        margin-bottom: 10px;
    }
}
